import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Sunjoy from "../../Assets/Projects/Sunjoy.png";
import Schumacher from "../../Assets/Projects/Schumacher.png";
import layla from "../../Assets/Projects/layla.png";
import Law from "../../Assets/Projects/Law.png";
import inceptionXr from "../../Assets/Projects/inceptionXr.png";
import tlv from "../../Assets/Projects/Tel-Aviv-Yaffo.png";
import siman from "../../Assets/Projects/siman-website-development.png"
import convertingdigital from "../../Assets/Projects/convertingdigital-development.png"
import folloapp from "../../Assets/Projects/folloapp.png"
import maaglim from "../../Assets/Projects/maaglim.png";
import cadesign from "../../Assets/Projects/cadesign.jpeg";
import firstmedia from "../../Assets/Projects/firstmedia.webp";
import hortica from "../../Assets/Projects/hortica.png";
import learn_german from "../../Assets/Projects/learn_german.png";
import mod from "../../Assets/Projects/mod.jpeg";
import loveme from "../../Assets/Projects/Loveme.png";
import c4 from "../../Assets/Projects/c4.jpeg";
import dk from "../../Assets/Projects/dk.png";

import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineWhatsApp
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tlv}
              isBlog={false}
              title="Tel-Aviv City (עיריית תל אביב-יפו)"
              description="Web Development"
              link="https://www.tel-aviv.gov.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mod}
              isBlog={false}
              title="Israeli Ministry of Defense"
              description="Software Services Provider"
              link="https://www.mod.gov.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={loveme}
              isBlog={false}
              title="LoveMe"
              description="Software Services"
              link="https://www.loveme.co.il/en/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={c4}
              isBlog={false}
              title="C4 Systems"
              description="Full Stack Software Services"
              link="https://www.c4systems.co.il/en/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dk}
              isBlog={false}
              title="Deepkeep LTD"
              description="Full Stack Software Services"
              link="https://deepkeep.ai/home"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={firstmedia}
              isBlog={false}
              title="First Media - Python, GCP And AWS Architecture"
              description="Utilizing Google Cloud And AWS For Different ETL operations Written In Python"
              link="https://first.media/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={maaglim}
              isBlog={false}
              title="Maaglim - Complete ERP System Development"
              description="Hours Management System, Full Stack Development, PHP With Laravel Framework"
              link="https://www.maagalimds.co.il/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={folloapp}
              isBlog={false}
              title="Folloapp - IOS and Android Application"
              description="Full Stack Development, C# And React Native"
              link="https://cnaan.co.il/%D7%9B%D7%9C%D7%9C%D7%99/folloapp-%D7%9B%D7%9A-%D7%AA%D7%A0%D7%94%D7%9C%D7%95-%D7%90%D7%AA-%D7%94%D7%9B%D7%A1%D7%A3-%D7%95%D7%94%D7%91%D7%99%D7%98%D7%95%D7%97%D7%99%D7%9D-%D7%A9%D7%9C%D7%9B%D7%9D-%D7%95%D7%AA%D7%97%D7%A1/"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cadesign}
              isBlog={false}
              title="Cadesgin - Web Development And Python Development"
              description="Creating Python Models For A CAD Software And Web Development"
              link="https://cadesign64.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hortica}
              isBlog={false}
              title="Hortica - Python Development And AWS Architecture"
              description="Python Development and AWS Architecture"
              link="https://hortica.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={siman}
              isBlog={false}
              title="Siman Employment Program"
              description="Complete Development And Design"
              link="https://siman.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={inceptionXr}
              isBlog={false}
              title="inceptionXR"
              description="PHP And Javascript Devlopment"
              link="https://bookful.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={layla}
              isBlog={false}
              title="Layla Und Yom"
              description="E-Commerce Shop"
              link="https://layla-yom.de"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Sunjoy}
              isBlog={false}
              title="Sunjoy"
              description="Web Devlopment"
              link="https://sunjoy.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Schumacher}
              isBlog={false}
              title="Schumacher Car Dealership"
              description="Developing A Copmlete ERP System."
              link="https://schumacher-ctc.de/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={convertingdigital}
              isBlog={false}
              title="Marketing Agency Converting-Digital"
              description="Web Development"
              link="https://convertingdigital.com/"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Law}
              isBlog={false}
              title="Website For a law office"
              description="Web Development"
              link="https://www.poratlaw.co.il"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={learn_german}
              isBlog={false}
              title="Website For A Private Tutor"
              description="Web Development"
              link="https://e-germanit.com/"
            />
          </Col>




        </Row>
        <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dor-p"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dorp/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://wa.me/+972545723256?text=Howdy"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineWhatsApp />
                </a>
              </li>
            </ul>
          </Col>
      </Container>
    </Container>
    
  );
}

export default Projects;
