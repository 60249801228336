import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Dor Porat.</span>
            <br />
            <br />
             I am a programmer with 6+ years experience. Today I use my knowledge to build websites and various technological tools. Because of my experience in the field, I can offer a fast, reliable and a professional service. I am available for every question and I will always be happy to help with a first free call - whether I will manage the project or not. I am a fairly nice guy so don't hesitate calling me even if you don't have any idea what to do.
            <br />
            </p>

          <p style={{ color: "rgb(155 126 172)" }}>
          "Strive not to be a success, but rather to be of value."{" "}
          </p>
          <footer className="blockquote-footer">Albert Einstein
</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
